"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = vue_1.default.extend({
    name: 'CardHelp',
    props: {
        name: {
            type: String,
        },
    },
    methods: {
        getClasses() {
            const classes = ['card-help'];
            return classes.join(' ');
        },
    },
    computed: {
        url() {
            const anchor = this.name.toLowerCase().replaceAll(' ', '-');
            return 'https://github.com/terraforming-mars/terraforming-mars/wiki/Card-Details#' + anchor;
        },
    },
});
